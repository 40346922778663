import React from "react"
import { Link } from "gatsby"
import mainslider from "../../assets/images/hero/home-infotechno-main-slider-slide-01-image-01.webp"
import mainslidergirl from "../../assets/images/banners/pngegg (4).png"
// import digitaltransformation from "../../assets/images/banners/homepage-services/digital.jpg"
// import cloudadoption from "../../assets/images/banners/homepage-services/cloud.jpg"
// import softwaredevelopment from "../../assets/images/banners/homepage-services/software.png"
// import mobileapplication from "../../assets/images/banners/homepage-services/mobile-application.png"
// import datascience from "../../assets/images/banners/homepage-services/data.jpg"
// import artificialintel from "../../assets/images/banners/homepage-services/artificial.jpg"
// import financialsector from "../../assets/images/banners/homepage-industries/financial.jpg"
// import telecom from "../../assets/images/banners/homepage-industries/telecom.jpg"
// import government from "../../assets/images/banners/homepage-industries/government.jpg"
// import energy from "../../assets/images/banners/homepage-industries/energy-utilities.jpg"
// import logistics from "../../assets/images/banners/homepage-industries/logistics.jpg"
// import retail from "../../assets/images/banners/homepage-industries/retail.jpg"
import blog1 from "../../assets/images/banners/blog-1.jpg"

import banner1 from '../../assets/images/banners/icon-pack/ales-nesetril-Im7lZjxeLhg-unsplash.jpg'
import digitaltransformation from '../../assets/images/banners/icon-pack/undraw_online_ad_re_ol62.svg'
import cloudadoption from '../../assets/images/banners/icon-pack/undraw_cloud_hosting_7xb1.svg'
import mobileapplication from '../../assets/images/banners/icon-pack/undraw_order_confirmed_re_g0if.svg'
import datascience from '../../assets/images/banners/icon-pack/undraw_data_re_80ws.svg'
import artificialintel from '../../assets/images/banners/icon-pack/undraw_artificial_intelligence_re_enpp.svg'
import softwaredevelopment from '../../assets/images/banners/icon-pack/undraw_development_re_g5hq.svg'
import financialsector from '../../assets/images/banners/icon-pack/financial-profit.png'
import telecom from '../../assets/images/banners/icon-pack/radar.png'
import government from '../../assets/images/banners/icon-pack/government.png'
import energy from '../../assets/images/banners/icon-pack/solar-energy.png'
import logistics from '../../assets/images/banners/icon-pack/logistics.png'
import retail from '../../assets/images/banners/icon-pack/store.png'

import "../../assets/css/style.css"
import "../../assets/css/custom.css"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

export const Home = () => {
  return (
    <div className="" id="main-wrapper">
      <div className="site-wrapper-reveal">
        {/* <!--Infotechno Hero Start--> */}
        <div className="infotechno-hero infotechno-bg">
          <div className="container-fluid">
            <div className="row align-items-center">
              {/* <!--baseline--> */}
              <div className="col-lg-6 col-md-6">
                <div className="infotechno-hero-text  wow move-up">
                  <h6
                    className="banner-sub-text pb-1"
                    style={{ fontSize: "22px" }}
                  >
                    We help you innovate, improvise, digitalize to make your
                    idea a reality.{" "}
                  </h6>
                  <h1
                    style={{ fontSize: "44px" }}
                    className="font-weight--reguler mb-15"
                  >
                    Discover new {""}
                    Possibilities
                  </h1>
                  <div className="btn-text pt-3">
                    <Link to="/Blog1" className="ht-btn ht-btn-sm">
                      Explore Now{" "}
                      <span className="btn-icon">
                        <i className="far fa-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="infotechno-hero-inner-images">
                  <div className="infotechno-inner-one">
                    <img className="img-fluid" src={mainslider} alt="" />
                  </div>
                  <div className="infotechno-inner-two  wow move-up">
                    <img className="img-fluid" src={mainslidergirl} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--============ Infotechno Hero End ============--> */}

        {/* <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper bg-gray section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our Services</h6>
                  <h2 className="heading">
                    <span className="">
                      We have tailored IT solutions for your specific industry.
                    </span>
                  </h2>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-lg-4 col-md-6 wow move-up">
                <Link
                  to="/digital-transformation"
                  className="ht-large-box-images style-03"
                >
                  <div className="large-image-box">
                    <div className="box-image">
                      <div className="default-image">
                        <img
                          className="img-fluid"
                          src={digitaltransformation}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="content">
                      <h5 className="heading">Digital Transformation</h5>
                      <div className="text">
                        As digital transformation is a journey, not a
                        destination, you will need an experienced team to help
                        you...
                      </div>
                      <div href="#" className="box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 wow move-up">
                <Link
                  to="/cloud-adoption"
                  className="ht-large-box-images style-03"
                >
                  <div className="large-image-box">
                    <div className="box-image">
                      <div className="default-image">
                        <img className="img-fluid" src={cloudadoption} alt="" />
                      </div>
                    </div>
                    <div className="content">
                      <h5 className="heading">Cloud Adoptions</h5>
                      <div className="text">
                        Aamanto will support you with our in-depth cloud
                        engineering expertise and over years of experience
                        modernizing legacy...
                      </div>
                      <div href="#" className="box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 wow move-up">
                <Link
                  to="/custom-software-development"
                  className="ht-large-box-images style-03"
                >
                  <div className="large-image-box">
                    <div className="box-image">
                      <div className="default-image">
                        <img
                          className="img-fluid"
                          src={softwaredevelopment}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="content">
                      <h5 className="heading">Bespoke Software Development</h5>
                      <div className="text">
                        Aamanto is a full-service custom software development,
                        cloud engineering, quality assurance...
                      </div>
                      <div href="#" className="box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 wow move-up">
                <Link
                  to="/mobile-application-service"
                  className="ht-large-box-images style-03"
                >
                  <div className="large-image-box">
                    <div className="box-image">
                      <div className="default-image">
                        <img
                          className="img-fluid"
                          src={mobileapplication}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="content">
                      <h5 className="heading">
                        Mobile Application Development
                      </h5>
                      <div className="text">
                        We're pushing the boundaries of what's possible on
                        mobile by developing revolutionary experiences for
                        clients...
                      </div>
                      <div href="#" className="box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 wow move-up">
                <Link
                  to="/data-science-analytics"
                  className="ht-large-box-images style-03"
                >
                  <div className="large-image-box">
                    <div className="box-image">
                      <div className="default-image">
                        <img className="img-fluid" src={datascience} alt="" />
                      </div>
                    </div>
                    <div className="content">
                      <h5 className="heading">Data Science & Analytics</h5>
                      <div className="text">
                        Aamanto enables businesses to provide quality services
                        to their customers by assisting them. It utilizes
                        existing...
                      </div>
                      <div href="#" className="box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 wow move-up">
                <Link
                  to="/artificial-intelligence"
                  className="ht-large-box-images style-03"
                >
                  <div className="large-image-box">
                    <div className="box-image">
                      <div className="default-image">
                        <img
                          className="img-fluid"
                          src={artificialintel}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="content">
                      <h5 className="heading">Artificial Intelligence</h5>
                      <div className="text">
                        At Aamanto we use block chain and AI to turn
                        sophisticated products into quick fixes. We have a large
                        team of highly...
                      </div>
                      <div href="#" className="box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="v1-default-box">
                  <Link to="digital-transformation">
                    <img className="img-fluid" src={digitaltransformation} alt="" />
                  </Link>
                  <div className="v1-content">
                    <h5 className="v1-heading">Digital Transformation</h5>
                    <div className="v1-text">
                      As digital transformation is a journey, not a
                      destination, you will need an experienced team to help
                      you...
                    </div>
                    <Link to="digital-transformation">
                      <div className="v1-box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="v1-default-box">
                  <Link to="cloud-adoption">
                    <img className="img-fluid" src={cloudadoption} alt="" />
                  </Link>
                  <div className="v1-content">
                    <h5 className="v1-heading">Cloud Adoptions</h5>
                    <div className="v1-text">
                      Aamanto will support you with our in-depth cloud engineering expertise and over years of experience modernizing legacy...
                    </div>
                    <Link to="cloud-adoption">
                      <div className="v1-box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="v1-default-box">
                  <Link to="custom-software-development">
                    <img className="img-fluid" src={softwaredevelopment} alt="" />
                  </Link>
                  <div className="v1-content">
                    <h5 className="v1-heading">Bespoke Software Development</h5>
                    <div className="v1-text">
                      Aamanto is a full-service custom software development, cloud engineering, quality assurance...
                    </div>
                    <Link to="custom-software-development">
                      <div className="v1-box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="v1-default-box">
                  <Link to="mobile-application-service">
                    <img className="img-fluid" src={mobileapplication} alt="" />
                  </Link>
                  <div className="v1-content">
                    <h5 className="v1-heading">Mobile Application Development</h5>
                    <div className="v1-text">
                      We're pushing the boundaries of what's possible on mobile by developing revolutionary experiences for clients...
                    </div>
                    <Link to="mobile-application-service">
                      <div className="v1-box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="v1-default-box">
                  <Link to="data-science-analytics">
                    <img className="img-fluid" src={datascience} alt="" />
                  </Link>
                  <div className="v1-content">
                    <h5 className="v1-heading">Data Science & Analytics</h5>
                    <div className="v1-text">
                      Aamanto enables businesses to provide quality services to their customers by assisting them. It utilizes existing...
                    </div>
                    <Link to="data-science-analytics">
                      <div className="v1-box-images-arrow">
                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="v1-default-box">
                  <Link to="artificial-intelligence">
                    <img className="img-fluid" src={artificialintel} alt="" />
                  </Link>
                  <div className="v1-content">
                    <h5 className="v1-heading">Artificial Intelligence</h5>
                    <div className="v1-text">
                      At Aamanto we use block chain and AI to turn sophisticated products into quick fixes. We have a large team of highly...
                    </div>
                    <Link to="artificial-intelligence">



                      <div className="v1-box-images-arrow">

                        <span className="button-text">Read More</span>
                        <i className="far fa-long-arrow-right"></i>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* <!--===========  feature-large-images-wrapper  End =============--> */}

      {/* <!--=========== Infotechno Video Wrapper Start ==========--> */}
      {/* <div className="infotechno-video-wrapper infotechno-video-ptb infotechno-video-bg section-space--mt_10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-10">
              <div className="video-content-wrap">
                <div className="vieeo-content-inner">
                  <h2 className="heading text-center">
                    Your technology partner for innovative and impactful digital
                    solutions
                  </h2>
                  <div className="viewo-text-wrap">
                    <div className="video-popup infotech-video-box">
                      <Link
                        to="https://www.youtube.com/watch?v=9No-FiEInLA"
                        className="single-popup-wrap video-link"
                      >
                        <div className="video-content">
                          <div className="ht-popup-video video-button">
                            <div className="video-mark">
                              <div className="wave-pulse wave-pulse-1"></div>
                              <div className="wave-pulse wave-pulse-2"></div>
                            </div>
                            <div className="video-button__two">
                              <div className="video-play">
                                <span className="video-play-icon"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!--=========== Infotechno Video Wrapper End ==========--> */}

      <div className="picture-btw-div">
        <div className="row">
          <div className="col-md-6 picture-btw-div-text-col">
            <div className="picture-btw-div-text">
              <h2>Your technology partner for innovative and impactful digital solutions.</h2>
            </div>
          </div>
          <div className="col-md-6">
            <div className="picture-btw-div-img">
              <img className="img-fluid" src={banner1}></img>
            </div>
          </div>
        </div>
      </div>

      {/* <!--===========  feature-large-images-wrapper  Start =============--> */}
      <div className="feature-large-images-wrapper section-space--ptb_80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <!-- section-title-wrap Start --> */}
              <div className="section-title-wrap text-center section-space--mb_60">
                <h6 className="section-sub-title">Our Industries</h6>
                <h2 className="heading">Providing Digital Business Solutions To a Wide Range of Industries.</h2>
              </div>
              {/* <!-- section-title-wrap Start --> */}
            </div>
          </div>

          {/* <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-4 col-md-6 wow move-up">
                  <Link
                    to="/financial-sector"
                    className="ht-large-box-images style-03"
                  >
                    <div className="large-image-box">
                      <div className="box-image">
                        <div className="default-image">
                          <img
                            className="img-fluid"
                            src={financialsector}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">Financial Sector</h5>
                        <div className="text">
                          Financial service companies constantly deal with
                          issues such as improving service quality in today’s
                          fast...
                        </div>
                        <div href="#" className="box-images-arrow">
                          <span className="button-text">Read More</span>
                          <i className="far fa-long-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 wow move-up">
                  <Link
                    to="/telecommunication"
                    className="ht-large-box-images style-03"
                  >
                    <div className="large-image-box">
                      <div className="box-image">
                        <div className="default-image">
                          <img className="img-fluid" src={telecom} alt="" />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">Telecommunications</h5>
                        <div className="text">
                          Aamanto enables telecommunication organizations in
                          adopting old infrastructure to changing markets...
                        </div>
                        <div href="#" className="box-images-arrow">
                          <span className="button-text">Read More</span>
                          <i className="far fa-long-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 wow move-up">
                  <Link
                    to="/government"
                    className="ht-large-box-images style-03"
                  >
                    <div className="large-image-box">
                      <div className="box-image">
                        <div className="default-image">
                          <img className="img-fluid" src={government} alt="" />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">Government</h5>
                        <div className="text">
                          For more than a decade, we have been at the forefront
                          of offering business solutions in the government
                          sector...
                          <br />
                        </div>
                        <div href="#" className="box-images-arrow">
                          <span className="button-text">Read More</span>
                          <i className="far fa-long-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 wow move-up">
                  <Link
                    to="/energy-utilities"
                    className="ht-large-box-images style-03"
                  >
                    <div className="large-image-box">
                      <div className="box-image">
                        <div className="default-image">
                          <img className="img-fluid" src={energy} alt="" />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">Energy & Utilities</h5>
                        <div className="text">
                          The need to optimize energy generation and use is
                          becoming more important than ever as technology...
                        </div>
                        <div href="#" className="box-images-arrow">
                          <span className="button-text">Read More</span>
                          <i className="far fa-long-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 wow move-up">
                  <Link
                    to="/logistics-transportation"
                    className="ht-large-box-images style-03"
                  >
                    <div className="large-image-box">
                      <div className="box-image">
                        <div className="default-image">
                          <img className="img-fluid" src={logistics} alt="" />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">Logistics & Transportation</h5>
                        <div className="text">
                          Helping Logistics and Shipping Companies manage their
                          business with ease and efficiency...
                        </div>
                        <div href="#" className="box-images-arrow">
                          <span className="button-text">Read More</span>
                          <i className="far fa-long-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 wow move-up">
                  <Link to="/retail" className="ht-large-box-images style-03">
                    <div className="large-image-box">
                      <div className="box-image">
                        <div className="default-image">
                          <img className="img-fluid" src={retail} alt="" />
                        </div>
                      </div>
                      <div className="content">
                        <h5 className="heading">Retail Industry</h5>
                        <div className="text">
                          Aamanto is the go-to solutions provider for merchants
                          hit by ecommerce because of its full-stack development
                          abilities...
                        </div>
                        <div href="#" className="box-images-arrow">
                          <span className="button-text">Read More</span>
                          <i className="far fa-long-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}

          <div class="v1-box-2-parent">
            <div class="v1-box-2-1-child">
              <div className="v1-box-2-content">
                <Link to="/financial-sector">
                  <div className="v1-default-box-2">
                    <div className="row">
                      <div className="col-3 v1-img-2">
                        <img className="img-fluid" src={financialsector} alt="" />
                      </div>
                      <div className="col-9">
                        <div className="v1-content-2">
                          <h5 className="v1-heading-2">Financial Sector</h5>
                          <div className="v1-text-2">
                            Financial service companies constantly deal with
                            issues such as improving service quality in today’s
                            fast...
                          </div>
                          <div href="#" className="v1-box-images-arrow-2">
                            <span className="button-text">Read More</span>
                            <i className="far fa-long-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div class="v1-box-2-1-child">
              <div className="v1-box-2-content">
                <Link to="/telecommunication">
                  <div className="v1-default-box-2">
                    <div className="row">
                      <div className="col-3 v1-img-2">
                        <img className="img-fluid" src={telecom} alt="" />
                      </div>
                      <div className="col-9">
                        <div className="v1-content-2">
                          <h5 className="v1-heading-2">Telecommunications</h5>
                          <div className="v1-text-2">
                            Aamanto enables telecommunication organizations in adopting old infrastructure to changing markets...
                          </div>
                          <div href="#" className="v1-box-images-arrow-2">
                            <span className="button-text">Read More</span>
                            <i className="far fa-long-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div class="v1-box-2-1-child">
              <div className="v1-box-2-content">
                <Link to="/government">
                  <div className="v1-default-box-2">
                    <div className="row">
                      <div className="col-3 v1-img-2">
                        <img className="img-fluid" src={government} alt="" />
                      </div>
                      <div className="col-9">
                        <div className="v1-content-2">
                          <h5 className="v1-heading-2">Government</h5>
                          <div className="v1-text-2">
                            For more than a decade, we have been at the forefront of offering business solutions in the government sector...
                          </div>
                          <div href="#" className="v1-box-images-arrow-2">
                            <span className="button-text">Read More</span>
                            <i className="far fa-long-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div class="v1-box-2-1-child">
              <div className="v1-box-2-content">
                <Link to="/energy-utilities">
                  <div className="v1-default-box-2">
                    <div className="row">
                      <div className="col-3 v1-img-2">
                        <img className="img-fluid" src={energy} alt="" />
                      </div>
                      <div className="col-9">
                        <div className="v1-content-2">
                          <h5 className="v1-heading-2">Energy & Utilities</h5>
                          <div className="v1-text-2">
                            The need to optimize energy generation and use is becoming more important than ever as technology...
                          </div>
                          <div href="#" className="v1-box-images-arrow-2">
                            <span className="button-text">Read More</span>
                            <i className="far fa-long-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div class="v1-box-2-1-child">
              <div className="v1-box-2-content">
                <Link to="/logistics-transportation">
                  <div className="v1-default-box-2">
                    <div className="row">
                      <div className="col-3 v1-img-2">
                        <img className="img-fluid" src={logistics} alt="" />
                      </div>
                      <div className="col-9">
                        <div className="v1-content-2">
                          <h5 className="v1-heading-2">Logistics & Transportation</h5>
                          <div className="v1-text-2">
                            Helping Logistics and Shipping Companies manage their business with ease and efficiency...
                          </div>
                          <div href="#" className="v1-box-images-arrow-2">
                            <span className="button-text">Read More</span>
                            <i className="far fa-long-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div class="v1-box-2-1-child">
              <div className="v1-box-2-content">
                <Link to="/retail">
                  <div className="v1-default-box-2">
                    <div className="row">
                      <div className="col-3 v1-img-2">
                        <img className="img-fluid" src={retail} alt="" />
                      </div>
                      <div className="col-9">
                        <div className="v1-content-2">
                          <h5 className="v1-heading-2">Retail Industry</h5>
                          <div className="v1-text-2">
                            Aamanto is the go-to solutions provider for merchants because of its full-stack development abilities...
                          </div>
                          <div href="#" className="v1-box-images-arrow-2">
                            <span className="button-text">Read More</span>
                            <i className="far fa-long-arrow-right"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

          </div>

        </div>
      </div>

      {/* <!--===========  feature-large-images-wrapper  End =============--> */}

      {/* <!--====================  Blog Section Start ====================--> */}
      <div className="blog-section-wrapper section-space--pb_80">
        <div className="container">
          <div className="row">

            <div class="section-title-wrap text-center section-space--mb_60">
              <h6 class="section-sub-title">Blogs & News</h6>
              <h2 class="heading">Providing Digital Business Solutions To a Wide Range of Industries<span class="text-color-primary"></span></h2>
            </div>

            <div className="col-lg-4 col-md-12 wow move-up">
              <div className="section-title">
                <h3 className="heading">
                  More Interesting Articles
                  <span className="text-color-primary"> Updated Daily</span>
                </h3>
                <ul
                  className="infotechno-blog-list mt-30"
                  style={{ paddingLeft: "0px" }}
                >
                  <li>
                    <Link to="/Blog1">6 Stages of Cloud Adoption</Link>
                  </li>
                  <li>
                    <Link to="/Blog2">
                      How AI Chatbot Technology is Changing The Health Industry
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow move-up">
              {/* <!--======= Single Blog Item Start ========--> */}
              <div className="single-blog-item blog-grid">
                {/* <!-- Post Feature Start --> */}
                <div className="post-feature blog-thumbnail">
                  <Link to="/Blog1">
                    <img
                      className="img-fluid"
                      src="https://media.istockphoto.com/photos/abstract-cloud-computing-technology-concept-picture-id1316144933?b=1&k=20&m=1316144933&s=170667a&w=0&h=jQa0mn3U1ePZMYdTY7zisheCQqUItm2_itt_Jq5UZmU="
                      alt="Blog Images"
                    />
                  </Link>
                </div>
                {/* <!-- Post Feature End --> */}

                {/* <!-- Post info Start --> */}
                <div className="post-info lg-blog-post-info">
                  <div className="post-meta">
                    <div className="post-date">
                      <span className="far fa-calendar meta-icon"></span>
                      February 28, 2019
                    </div>
                  </div>

                  <h5 className="post-title font-weight--bold">
                    <Link to="/Blog1">6 Stages of Cloud Adoption</Link>
                  </h5>
                  <br />

                  <div className="post-excerpt mt-15">
                    <p className="neg-mt-30">
                      Cloud is the future; in the coming time, everything will
                      be handled within the cloud systems, and we won't have to
                      worry about.
                    </p>
                  </div>
                  <div className="btn-text">
                    <Link to="/Blog1" className="ht-btn ht-btn-sm">
                      Read More{" "}
                      <span className="btn-icon">
                        <i className="far fa-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
                {/* <!-- Post info End --> */}
              </div>
              {/* <!--===== Single Blog Item End =========--> */}
            </div>

            <div className="col-lg-4 col-md-6 wow move-up">
              {/* <!--======= Single Blog Item Start ========--> */}
              <div className="single-blog-item blog-grid">
                {/* <!-- Post Feature Start --> */}
                <div className="post-feature blog-thumbnail">
                  <Link to="/Blog2">
                    <img className="img-fluid" src={blog1} alt="Blog Images" />
                  </Link>
                </div>
                {/* <!-- Post Feature End --> */}

                {/* <!-- Post info Start --> */}
                <div className="post-info lg-blog-post-info">
                  <div className="post-meta">
                    <div className="post-date">
                      <span className="far fa-calendar meta-icon"></span>
                      February 28, 2019
                    </div>
                  </div>

                  <h5 className="post-title font-weight--bold">
                    <Link to="/Blog2">
                      How AI Chatbot Technology is Changing The Health Industry
                    </Link>
                  </h5>

                  <div className="post-excerpt mt-15">
                    <p>
                      There is no denying that artificial intelligence is having
                      a significant impact on the healthcare industry. According
                      to Accenture research.
                    </p>
                  </div>
                  <div className="btn-text">
                    <Link to="/Blog2" className="ht-btn ht-btn-sm">
                      Read More{" "}
                      <span className="btn-icon">
                        <i className="far fa-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
                {/* <!-- Post info End --> */}
              </div>
              {/* <!--===== Single Blog Item End =========--> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!--====================  Blog Section End  ====================--> */}
    </div>
  )
}
export default Home
