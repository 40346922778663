import * as React from "react"
import Layout from "../components/layout"
import Home from "../components/Index/Home"

const home = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}
export default home
